<template>
  <div id='comparison'>
    <period-selector />
    <div class='divider' style="margin-top: -10px;"></div>
    <chart-container />
  </div>
</template>
<script>
  import PeriodSelector from './PeriodSelector'
  import ChartContainer from './ChartContainer'
  import store from './store'

  export default {
    store: store,
    props: ['labels', 'metrics', 'departments', 'analysisGroups', 'components', 'npsComponents', 'npsValues', 'rollingMetrics', 'rollingComponents', 'currentPeriod', 'availableDepartments', 'availableAnalysisGroups', 'selectedDepartmentIds'],
    created() {
      this.$store.state.labels = this.labels
      this.$store.state.metrics = this.metrics
      this.$store.state.departments = this.departments
      this.$store.state.analysisGroups = this.analysisGroups
      this.$store.state.components = this.components
      this.$store.state.npsComponents = this.npsComponents
      this.$store.state.npsValues = this.npsValues
      this.$store.state.rollingMetrics = this.rollingMetrics
      this.$store.state.rollingComponents = this.rollingComponents
      this.$store.state.currentPeriod = this.currentPeriod
      this.$store.state.selectedDepartmentIds = this.selectedDepartmentIds
      this.$store.state.availableDepartments = this.availableDepartments
      this.$store.state.availableAnalysisGroups = this.availableAnalysisGroups
      if(this.currentPeriod === 'one_quarter') {
        this.$store.dispatch('fetchRollingResponseRates', {label: '3 months', months: 3, initial: true})
      } else {
        this.$store.dispatch('fetchRollingResponseRates', {label: '1 month', months: 1, initial: true})
      }
    },
    computed: {
      filteredLabels() {
        return this.$store.getters.filteredLabels
      }
    },
    methods: {},
    components: {
      PeriodSelector,
      ChartContainer
    }
  }
</script>
