<template>
  <div class='row'>
    <div class='col-6'>
      <div class='time-period-select'>
        <span v-for='period in periodFilters' class='pointer' :class='{active: period.active}' @click='changePeriod(period)'>
          {{period.label}}
        </span>
      </div>
    </div>   
    <div class='col-6 d-flex justify-content-end'> 
      <FilterResultsDropdown :availableDepartments="this.$store.state.departments"
                             :availableAnalysisGroups="this.$store.state.analysisGroups"
                             :selectedDepartmentIds="this.$store.state.selectedDepartmentIds"
                             section="comparison" style="margin-top: -10px"/>
      <div class='f-right tooltip-icon'>
        <i v-tooltip='t("components.analysis.comparison.period_selector.hint",
         {
           current_cycle: currentCycleDate,
           compared_cycle: comparedCycleDate
         }
        )' class='fa fa-lg fa-question-circle'></i>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import FilterResultsDropdown from './FilterResultsDropdown.vue';

  export default {
    components: {
      FilterResultsDropdown
    },
    computed: {
      periodFilters() {
        const periodSelectorFilters = this.$store.state.periodSelectorFilters

        if (this.$store.state.currentPeriod == 'one_quarter') {
          periodSelectorFilters[1].active = true // set 3 months as the active period if surveys are once a quarter
          return periodSelectorFilters.slice(1)
        } else {
          return periodSelectorFilters
        }
      },
      currentCycleDate() {
        let date = this.$store.state.rollingMetrics[0]?.cycle_date

        return date ? moment(date).format('DD.MM.YYYY') : ' - '
      },
      comparedCycleDate() {
        let date = this.$store.state.rollingMetrics.filter(m => m.previous_cycle_date)[0]?.previous_cycle_date;

        return date ? moment(date).format('DD.MM.YYYY') : ' - '
      }
    },

    methods: {
      changePeriod(period) {
        this.$store.dispatch('fetchRollingMetrics', period)
        this.$store.dispatch('fetchRollingComponents', period)
        this.$store.dispatch('fetchRollingResponseRates', period)
        this.$store.dispatch('fetchRollingNpsComponents', period)
      }
    }
  }
</script>
